// 公用api
import request from '@/tools/request';

// 数据统计
export function statis(data) {
  return request({
    url: `/pricecutsremind-statis-index.html`,
    method: 'get',
    params: data,
  });
}
